import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, Tooltip, Typography, IconButton, Box } from "@mui/material";
import { invokeNamed } from "../../async/ClientConfigData";
import { PIPELINE_PUBLISH_SFN } from "../../util/GlobalVars";
import { useNavigate } from "react-router-dom";
import { StartExecutionCommandOutput } from "@aws-sdk/client-sfn";
import { root } from "../../routes/Routes";
import { IToast } from "./Toast";
import { useState } from "react";
import { PipelinePublishDialogLayers, VECTORS_REGEX } from "./PipelinePublishDialogLayers";
import HelpIcon from '@mui/icons-material/Help';
import { msalInstance } from "../../index";
import { isDatahubAdmin } from "../../auth/Auth";

export interface PipelinePublishDialogProps {
    value: any;
    open: boolean;
    closeDialog: () => void;
    setValue: (attribute_name: string, value: any) => void;
    setRootValue: (attribute_name: string, value: any) => void;
    setToast: (toastProps: IToast) => void;
}

export interface PipelinePublishDialogData {
    open: boolean;
    value: any | null;
}

export const initialPipelinePublishDialogState = {
    open: false,
    value: null
};

export const getPipelinePublishDialogFunctions = (publishDialogData: PipelinePublishDialogData, setPublishDialogData: React.Dispatch<React.SetStateAction<PipelinePublishDialogData>>) => {
    const openDialog = (value: any) => {
        setPublishDialogData({
            open: true,
            value
        });
    };

    const setValue = (attribute_name: string, value: any) => {
        setPublishDialogData({
            open: true,
            value: {
                ...publishDialogData.value,
                [attribute_name]: value
            }
        })
    };

    const setRootValue = (attribute_name: string, value: any) => {
        setPublishDialogData({
            open: true,
            value: {
                ...publishDialogData.value,
                root: {
                    ...publishDialogData.value.root,
                    [attribute_name]: value
                }
            }
        })
    };

    const closeDialog = () => {
        setPublishDialogData({
            open: false,
            value: null
        });
    };

    return {
        openDialog,
        setValue,
        setRootValue,
        closeDialog
    }
};

const FieldLabel = ({ label, tooltip }: { label: string, tooltip: string }) => {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography>{label}</Typography> 
        <Tooltip title={tooltip}>
            <IconButton>
                <HelpIcon />
            </IconButton>
        </Tooltip>
    </Box>;
};

const PipelinePublishDialog = ({ value, open, closeDialog, setValue, setRootValue, setToast }: PipelinePublishDialogProps) => {
    const account = msalInstance.getActiveAccount();
    const isAdmin = isDatahubAdmin(account);

    const navigate = useNavigate();

    const [advanced, setAdvanced] = useState(false);
    const [skipExtraction, setSkipExtraction] = useState(false);
    const [skipCalculation, setSkipCalculation] = useState(false);
    const [skipShapefiles, setSkipShapefiles] = useState(false);
    const [skipDataGrids, setSkipDataGrids] = useState(false);
    const [skipTply, setSkipTply] = useState(false);
    const [skipObjectBrowser, setSkipObjectBrowser] = useState(false);
    const [skipTransformation, setSkipTransformation] = useState(false);
    const [layersFiltered, setLayersFiltered] = useState(false);

    const getOnCheckChanged = (setChecked: React.Dispatch<React.SetStateAction<boolean>>, attribute_name: string, setValue: (attribute_name: string, value: any) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setChecked(checked);
        setValue(attribute_name, checked);
    };

    const onAdvancedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdvanced(event.target.checked);
    };

    const navigateToExecution = (execution: StartExecutionCommandOutput) => {
        closeDialog();
        const search = execution.executionArn !== undefined ? `?execution=${execution.executionArn}` : undefined
        navigate({
            pathname: root,
            search
        });
    };

    const showError = (error: Error) => {
        setToast({ open: true, messageType: 'error', text: 'Named publish failed', error });
        console.log(error);
    };

    const handlePublish = () => {
        const inputValue = {
            input: value
        };
        invokeNamed(PIPELINE_PUBLISH_SFN, inputValue).then(navigateToExecution).catch(showError);
    };

    const onLayersFilteredChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setLayersFiltered(checked);
        if (checked) {
            setRootValue('layer_filter', [VECTORS_REGEX])
        } else {
            setRootValue('layer_filter', []);
        }
    };

    const onClose = () => {
        closeDialog();
        setAdvanced(false);
        setSkipExtraction(false);
        setSkipCalculation(false);
        setSkipShapefiles(false);
        setSkipDataGrids(false);
        setSkipTply(false);
        setSkipObjectBrowser(false);
        setLayersFiltered(false);
    };

    const maxWidth = advanced ? (layersFiltered ? 'md' : 'sm') : 'xs';
    const layerControlsActive = advanced === true && layersFiltered === true;
    const checkboxsMarginRight = layerControlsActive ? { marginRight: '12px' } : {};

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 700 } }}
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Publish Confirmation</DialogTitle>
            <DialogContent dividers>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: '1 1 0', marginRight: '12px' }}>
                        <pre>
                            {
                                JSON.stringify(value, null, 4)
                            }
                        </pre>
                        <FormControlLabel control={<Checkbox checked={advanced} onChange={onAdvancedChange} />} label="Advanced" />
                    </div>
                    {
                        advanced === true ? <div style={{
                            ...checkboxsMarginRight,
                            ...{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }
                        }
                        }>
                            <FormControlLabel control={<Checkbox checked={skipExtraction} onChange={getOnCheckChanged(setSkipExtraction, 'skip_extraction', setValue)} />} 
                                label={<FieldLabel label="Skip Extraction" tooltip="Skip the extraction of datasets from SQL Server Pipeline, used when we already have the datasets available on S3" />} />
                            <FormControlLabel control={<Checkbox checked={skipCalculation} onChange={getOnCheckChanged(setSkipCalculation, 'calculations_is_skipped', setValue)} />} 
                                label={<FieldLabel label="Skip Calculations" tooltip="Skip the execution of calculations - Used when the datasets we are using for a fiven publish already have all the calculations required" />} />
                            <FormControlLabel control={<Checkbox checked={skipShapefiles} onChange={getOnCheckChanged(setSkipShapefiles, 'shapefiles_is_skipped', setValue)} />}
                                label={<FieldLabel label="Skip Shapefiles" tooltip="Do not produce the shapefiles output" />} />
                            <FormControlLabel control={<Checkbox checked={skipDataGrids} onChange={getOnCheckChanged(setSkipDataGrids, 'datagrids_is_skipped', setValue)} />} 
                                label={<FieldLabel label="Skip DataGrids" tooltip="Do not populate the postgres server used by datagrids" />} />
                            <FormControlLabel control={<Checkbox checked={skipTply} onChange={getOnCheckChanged(setSkipTply, 'tply_is_skipped', setValue)} />} 
                                label={<FieldLabel label="Skip Tply" tooltip="Tply layers used by RW and Google Earth will not be produced" />} />
                            <FormControlLabel control={<Checkbox checked={skipObjectBrowser} onChange={getOnCheckChanged(setSkipObjectBrowser, 'object_browser_is_skipped', setValue)} />} 
                                label={<FieldLabel label="Skip Object Browser" tooltip="Skip the inserts on dynamo used by Object Browser PopUp" />} />
                            <FormControlLabel control={<Checkbox checked={skipTransformation} onChange={getOnCheckChanged(setSkipTransformation, 'skip_transformation', setValue)} />} 
                                label={<FieldLabel label="Skip Transformation" tooltip="Skip standard data transformations applied to all clients before calculations" />} />
                            <FormControlLabel control={<Checkbox checked={layersFiltered} onChange={onLayersFilteredChanged} />} 
                                label={<FieldLabel label="Layers:" tooltip="" />} />
                        </div> : <></>
                    }
                    {
                        layerControlsActive === true ? <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
                            <PipelinePublishDialogLayers value={value} setValue={setValue} setRootValue={setRootValue} setToast={setToast} />
                        </div> : <></>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handlePublish} disabled={!isAdmin}>Publish</Button>
            </DialogActions>
        </Dialog>
    );
};

export {
    PipelinePublishDialog
}